import * as React from "react"
import Markdown from "react-markdown"
import { useSelector } from "react-redux"
import { CSSTransition } from "react-transition-group"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Ideas from "../components/ideas/ideas"
import GlassParagraph from "../components/glassParagraph/glassParagraph"
import Slider from "../components/slider/slider"

import * as styles from "../assets/css/home.module.scss"

import { Strings } from "../constants/strings"
import colors from "../constants/colors"

import { selectCustomers } from "../redux/slices/customerSlice"
import { selectHomepageEntities } from "../redux/slices/homePageSlice"

const IndexPage = () => {
  const entities = useSelector(selectHomepageEntities)
  //DATAS TO SHOW ON THE PAGE
  const [activeCustomer, setActiveCustomer] = React.useState(null)

  const customers = useSelector(selectCustomers)

  return (
    <Layout>
      <Seo title={`Home - ${Strings.partners["en"]}`} />
      <div className="mbottom_5">
        <h2>{entities.entities && entities.entities.partners_title}</h2>
        <Slider arrowColor={colors.blue} viewAll={false} slidesToShow={4}>
          {customers.customers.map(({ attributes: elem, id }) => (
            <div
              role="button"
              onKeyDown={() => {}} //Due to ESLint warning
              tabIndex="0" //Due to ESLint warning
              onClick={() => setActiveCustomer(elem)}
              className={styles.client}
              key={id}
            >
              <div
                className={
                  styles.circle +
                  " " +
                  (elem.impression ? styles.client_active : "")
                }
              >
                <img src={elem.image.data.attributes.url} alt="client logo" />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <CSSTransition
        timeout={500}
        unmountOnExit
        mountOnEnter
        classNames="opacity"
        in={!!activeCustomer && !!activeCustomer.description}
      >
        <GlassParagraph
          image={activeCustomer?.image?.data?.attributes?.url}
          name={activeCustomer?.name}
          readMore="/"
        >
          <Markdown>{activeCustomer?.description}</Markdown>
        </GlassParagraph>
      </CSSTransition>
      <Ideas form={true} title={Strings.haveAProjectInMind["en"]} />
    </Layout>
  )
}

export default IndexPage
